import { AuthComponent } from 'components/auth/authComponent/AuthComponent'
import { AuthSocial } from 'components/auth/authSocial/AuthSocial'
import { SForgetText } from './LoginContainer.styled'
import { Button } from 'components/button/Button'
import { SecondaryLink } from 'components/Link'
import { TranslatableLink } from 'components/TranslatableLink'
import { Text } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { IFormPayload } from 'pages/signup'
import { UseFormReturn } from 'react-hook-form'
import { IAuthSocialProps } from 'utils/social'

export function LoginContainer({
  form,
  onFacebook,
  onGoogleSuccess,
  onGoogleFailure,
  registerHref,
  resetPasswordHref,
}: {
  form: UseFormReturn<IFormPayload>
  registerHref?: string
  resetPasswordHref?: string
} & IAuthSocialProps) {
  const { t } = useTranslation('auth')
  const loginAuthSocial = (
    <AuthSocial
      onFacebook={onFacebook}
      onGoogleSuccess={onGoogleSuccess}
      onGoogleFailure={onGoogleFailure}
    />
  )
  const loginButtonGroupElms = (
    <>
      {registerHref && (
        <Text>
          <Trans
            t={t}
            i18nKey="auth.login.register_text"
            components={[<TranslatableLink href={registerHref} key={0} variant="primary" />]}
          />
        </Text>
      )}{' '}
      <Button
        size="large"
        type="submit"
        data-testid="login-submit"
        loading={form.formState.isSubmitting}
      >
        {t('auth.login.login_button')}
      </Button>
      <SForgetText variant="bodySmall">
        {resetPasswordHref && (
          <Link href={resetPasswordHref} passHref legacyBehavior>
            <SecondaryLink>{t('auth.login.forgot_password_button')}</SecondaryLink>
          </Link>
        )}
      </SForgetText>
    </>
  )

  return (
    <AuthComponent
      oneClickTitle={t('auth.login.one_click_login_title')}
      authSocialElm={loginAuthSocial}
      emailTitle={t('auth.login.login_with_email_title')}
      form={form}
      buttonGroupElm={loginButtonGroupElms}
    />
  )
}
