import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import { Text } from 'components/Typography'

export const SLoginWrapper = styled.div`
  box-shadow: 0 0 40px 0 #00000026;
  border-radius: 2px;
  min-width: 0.78125rem;
  padding: 2.75rem;
  width: ${pxToRemUnit(518)};
  max-width: 100%;
  @media ${BREAKPOINTS.MD.max} {
    padding: 2.75rem 1.25rem;
  }
`

export const SForgetText = styled(Text)`
  text-align: center;
  align-self: center;
`
