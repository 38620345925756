import { postLogin } from 'api/goodtrust/auth'
import { handleAuthResponse, IAuthResponseOptions } from 'api/goodtrust/auth.util'
import { postFacebook, postGoogle } from 'api/goodtrust/social'
import { LoginContainer } from 'components/auth/loginContainer/LoginContainer'
import { Icon } from 'components/icon'
import { HStack, Stack } from 'components/Stack'
import { handleAndToastError } from 'components/Toast'
import { Caption, Title, Title_h3_default } from 'components/Typography'
import { createDirectiveAndRedirect } from 'logic/directive/create/create'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { IFormPayload } from 'pages/signup'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { colors } from 'utils/colors'
import { noop } from 'utils/general'
import { SESSION_NEW_USER_CREATED, setSessionValue } from 'utils/sessionStorage'
import { IFacebookCallback, IGoogleSuccessCallback, onGoogleFailureToastError } from 'utils/social'

export function Login({
  title,
  registerHref,
  resetPasswordHref,
  authOpts,
}: {
  title: string
  registerHref?: string
  resetPasswordHref?: string
  authOpts: IAuthResponseOptions
}) {
  // redeploy
  const { t } = useTranslation('auth')
  const { mutateAuth } = useAuth()

  const { query } = useRouter()
  const form = useForm<IFormPayload>({
    defaultValues: { email: '', password: '' },
  })

  const handleSubmit = async (payload: { email: string; password: string }) => {
    try {
      const res = await postLogin(payload.email, payload.password)
      const result = await handleAuthResponse(res, false, authOpts)
      if (result === false) return
      if (query?.flow === 'TRUST' || query?.flow === 'WILL') {
        await createDirectiveAndRedirect(query.flow, { isLogged: true, routerMechanism: 'replace' })
      }
      setSessionValue(SESSION_NEW_USER_CREATED, 'false')
      await mutateAuth()
    } catch (err) {
      handleAndToastError(err)
    }
  }

  const onGoogleSuccess: IGoogleSuccessCallback = async (response) =>
    handleAuthResponse(await postGoogle(response.accessToken), null, authOpts)

  const onFacebook: IFacebookCallback = async (response) => {
    if (!response.accessToken) return
    return handleAuthResponse(await postFacebook(response.accessToken), null, authOpts)
  }

  const onGoogleFailure = (res: { details?: string; error: string }) =>
    onGoogleFailureToastError(res, 'failed_to_login')

  return (
    <FormProvider {...form}>
      <form onSubmit={(e) => form.handleSubmit(handleSubmit)(e).catch(noop)}>
        <Stack space={['1.5rem', '3rem']} css={{ alignItems: 'center' }}>
          <Title variant={Title_h3_default}>{title}</Title>
          <LoginContainer
            form={form}
            onFacebook={onFacebook}
            onGoogleSuccess={onGoogleSuccess}
            onGoogleFailure={onGoogleFailure}
            registerHref={registerHref}
            resetPasswordHref={resetPasswordHref}
          />
          <HStack css={{ alignSelf: 'center' }}>
            <Icon name="LockClosed" color={colors.gray[500]} />
            <Caption>{t('auth.login.encryption_text')}</Caption>
          </HStack>
        </Stack>
      </form>
    </FormProvider>
  )
}
